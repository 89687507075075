@mixin wh($w, $h: $w) {
    width: $w;
    height: $h;
}

//$option 1 flex居中 2定位居中  其他则是文本居中
@mixin center($option) {
    @if $option ==1 {
        display: flex;
        justify-content: center;
        align-items: center;
    } @else if $option ==2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else {
        height: $option;
        line-height: $option;
        text-align: center;
    }
}

@mixin fill($option: 1) {
    @if $option == 1 {
        position: absolute;
        width: 100%;
        height: 100%;
    } @else {
        position: fixed;
        width: 100vw;
        height: 100vh;
    }
    top: 0;
    left: 0;
}

@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin img($url) {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

@for $i from 1 through 10 {
    .mr-#{$i} {
        margin-right: 5px * $i;
    }
    .mt-#{$i} {
        margin-top: 5px * $i;
    }
    .ml-#{$i} {
        margin-left: 5px * $i;
    }
    .mb-#{$i} {
        margin-bottom: 5px * $i;
    }
    .mrow-#{$i} {
        margin: 0px 5px * $i;
    }
    .mcol-#{$i} {
        margin: 5px * $i 0px;
    }

    .pr-#{$i} {
        padding-right: 5px * $i;
    }
    .pt-#{$i} {
        padding-top: 5px * $i;
    }
    .pl-#{$i} {
        padding-left: 5px * $i;
    }
    .pb-#{$i} {
        padding-bottom: 5px * $i;
    }
    .prow-#{$i} {
        padding: 0px 5px * $i;
    }
    .pcol-#{$i} {
        padding: 5px * $i 0px;
    }
    .font-#{$i} {
        font-size: 10px + $i * 2;
    } 
}
